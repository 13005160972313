import axios from "axios";
import { userConstants, serverUrl } from "../_constants";
import { userService } from "../_services";
import { history } from "../_helpers";
import ym from 'react-yandex-metrika';

export const userActions = {
  login,
  logout,
  registr,
  conf_list,
  conf_student_list,
  set_conf_list,
  add_conf,
  edit_conf,
  del_conf,
  disable_error,
  set_error,
  teamorg,
  set_conf,
  set_conf_selected,
  getuser,
  setuser,
  get_schedule,
  loading,
  site_list,
  set_site,
  superAuth,
};

const UNAUTH = "Error: Request failed with status code 401";


const correct_err_text = {
    
'Your email address is not verified.':"your_email_address_not_verified",
    
}

function setCookie(name, value, options) {
  options = options || {};
  let { expires } = options;
  if (typeof expires === "number" && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }

  if (expires && expires.toUTCString) options.expires = expires.toUTCString();

  value = encodeURIComponent(value);
  let updatedCookie = `${name}=${value}`;
  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
}

function setStorage(name,val)
{
	localStorage.setItem(name,val);
}



function login(data) {
  const md5hash = data.password;
  const { username } = data;
  return (dispatch) => {
    dispatch(request({ username }));

    const data1 = JSON.stringify({ email: data.username, password: md5hash });

    // data1 = '{"email":"org1@example.org","password":"password"}';

    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.withCredentials = true;

    axios.post(`${serverUrl}api/token`, data1).then(
      (user) => {
        const data = { ...user.data };


        if (data.is_agreement_accepted == false) {
          dispatch(failure("No agreement"));
          setStorage("token_tmp", data.token);
          setStorage("token", "");
          history.push("/agreement");
        } else {
          setStorage("token", data.token);

          axios.defaults.headers.post['Authorization'] = 'Bearer '+user.data;
          axios.defaults.headers.get['Authorization'] = 'Bearer '+user.data;
          axios.defaults.headers.patch['Authorization'] = 'Bearer '+user.data;
          axios.defaults.headers.delete['Authorization'] = 'Bearer '+user.data;
          setStorage("navic_user", username);
          setStorage("navic_pwd", md5hash);

          let nc_red = localStorage.getItem("navic_redirect");

          dispatch(success(user));
          localStorage.setItem("conf", '{"id":0}');
          localStorage.setItem("user", "{}");
          localStorage.setItem("navic_redirect", "");
          if (nc_red) {
            localStorage.setItem("tickets_opened", "1");
            history.push(nc_red);
          } else {
            history.push("/");
          }
        }
      },
      (error) => {

        if (error == UNAUTH) history.push("/login");
        else 

        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function registr(data) {
  const md5hash = data.password;
  const { username } = data;

  function send_agr() {}

  return (dispatch) => {
    const data0 = {
      email: data.username,
      password: md5hash,
      password_confirmation: md5hash,
    };

    if (data.isStudent) data0.type = "student";

    const data1 = JSON.stringify(data0);

    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.withCredentials = true;

    axios.post(`${serverUrl}api/user`, data1).then(
      (user) => {
        

        if (user.data && user.data.token) {
          setStorage("token", user.data.token);
          setStorage("navic_user", username);
          setStorage("navic_pwd", md5hash);
          
    		let nc_red = localStorage.getItem("navic_redirect");

          dispatch(success(user));
          localStorage.setItem("conf", '{"id":0}');
          localStorage.setItem("user", "{}");
          localStorage.setItem("navic_redirect", "");
          ym('reachGoal', 'regComplete');
		  
	
          if (nc_red) {
			  localStorage.setItem("tickets_opened", "1");
			  history.push(nc_red);
		  }
		  else history.push("/");

        }
      },
      (error) => {
          
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REG_REQUEST, user };
  }

  function success(user) {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post.Authorization = `Bearer ${user.data.token}`;

    axios.post(`${serverUrl}api/sign/user-agreement`, {}).then(
      (user) => {
        console.log(user);
      },
      (error) => {
        console.log(error);
      }
    );

    return { type: userConstants.REG_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.REG_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function disable_error() {
  return { type: userConstants.DISABLE_ERROR };
}

function set_error(error_data, err_type = "error") {
      
   let error = error_data;
   console.log(error_data);
   
   let err_action = 0;
   
   if (error_data && error_data.response && error_data.response.data && error_data.response.data.message+'' != '')
   {
       error = ""+error_data.response.data.message;
       
   }
   
   if ((''+error).indexOf("SQLSTATE[")>=0)
       error = ""+error_data;
   
   if (error in correct_err_text)
   {
       error = correct_err_text[error];
       err_action = 1;
   }
  
  return { type: userConstants.SET_ERROR, error, err_type, err_action };
}

function loading(status) {
  return { type: userConstants.LOADING, status };
}

function set_conf(selectedConf) {
  try {
    
    const dataStr = JSON.stringify(selectedConf);
    localStorage.setItem("conf", dataStr);
  } catch (e) {
    
  }

  return { type: userConstants.SET_CONF, selectedConf };
}

function set_site(selectedSite) {
  try {
    
    const dataStr = JSON.stringify(selectedSite);
    localStorage.setItem("site", dataStr);
  } catch (e) {
    
  }

  return { type: userConstants.SET_SITE, selectedSite };
}

function site_list(data) {
  return (dispatch) => {
    dispatch(request({}));

    userService.getWrapper(
      "api/building",
      (data) => {

        dispatch(success(data.data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    
    return { type: userConstants.SITELIST_REQUEST, data };
  }

  function success(data) {
    
    const conflist = [...data];

    return { type: userConstants.SITELIST_SUCCESS, conflist };
  }

  function failure(error) {
    return { type: userConstants.SITELIST_FAILURE, error };
  }
}


function set_conf_selected() {
  return { type: userConstants.SET_CONF_SELECTED };
}

function add_conf(data_conf) {
  return (dispatch) => {
    dispatch(request(data_conf));

    const data1 = JSON.stringify(data_conf);

    userService.postWrapper(
      "api/conference",
      data1,
      (data) => {
        // dispatch(success(data.data));
        dispatch(success(data_conf));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    
    return { type: userConstants.ADDCONF_REQUEST, data };
  }

  function success(data) {
    return { type: userConstants.ADDCONF_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.ADDCONF_FAILURE, error };
  }
}

function edit_conf(conf_data, id) {
  return (dispatch) => {
    dispatch(request(conf_data));

    const data1 = JSON.stringify(conf_data);

    userService.patchWrapperBody(
      `api/conference/${id}`,
      data1,
      (data) => {
        dispatch(success(conf_data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    
    return { type: userConstants.EDITCONF_REQUEST, data };
  }

  function success(selectedConf) {
    return { type: userConstants.EDITCONF_SUCCESS, selectedConf };
  }

  function failure(error) {
    

    return { type: userConstants.EDITCONF_FAILURE, error };
  }
}

function del_conf(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delWrapper(
      `api/conference/${id}`,
      (data) => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(id) {
    
    return { type: userConstants.DELCONF_REQUEST, id };
  }

  function success(id) {
    return { type: userConstants.DELCONF_SUCCESS, id };
  }

  function failure(error) {
    
    return { type: userConstants.DELCONF_FAILURE, error };
  }
}

function setuser(userData) {
  return (dispatch) => {
    dispatch(success(userData));
  };

  function success(data) {
    

    const info = { ...data };

    return { type: userConstants.USERINFO_SUCCESS, info };
  }
}

function getuser() {
  return (dispatch) => {
    dispatch(request({}));

    userService.getWrapper(
      "api/user",
      (data) => {
        
        dispatch(success(data.data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    
    return { type: userConstants.USERINFO_REQUEST, data };
  }

  function success(data) {
    const info = { ...data };
    localStorage.setItem("user", JSON.stringify(info));

    return { type: userConstants.USERINFO_SUCCESS, info };
  }

  function failure(error) {
    localStorage.setItem("user", JSON.stringify({}));
    return { type: userConstants.USERINFO_FAILURE, error };
  }
}

function set_conf_list(data) {
  return { type: userConstants.CONFLIST_SUCCESS, conflist: [...data] };
}

function conf_list(data) {
  return (dispatch) => {
    dispatch(request({}));

    userService.getWrapper(
      "api/conference",
      (data) => {

        dispatch(success(data.data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    
    return { type: userConstants.CONFLIST_REQUEST, data };
  }

  function success(data) {
    
    const conflist = [...data];

    return { type: userConstants.CONFLIST_SUCCESS, conflist };
  }

  function failure(error) {
    return { type: userConstants.CONFLIST_FAILURE, error };
  }
}

function conf_student_list(notFinished=0,hasTicket=0) {
  return (dispatch) => {
    dispatch(request({}));

    userService.getWrapper(
      "api/conference-all?hasTicket="+hasTicket,
      (data) => {
        dispatch(success(data.data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    
    return { type: userConstants.CONFLIST_STUDENT_REQUEST, data };
  }

  function success(data) {
    
    const conflist = [...data];

    return { type: userConstants.CONFLIST_STUDENT_SUCCESS, conflist };
  }

  function failure(error) {
    return { type: userConstants.CONFLIST_STUDENT_FAILURE, error };
  }
}

function teamorg(data) {
  return (dispatch) => {
    dispatch(request({}));

    userService.getWrapper(
      "records/conference?join=building",
      (data) => {
        dispatch(success(data.data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: userConstants.TEAMORG_REQUEST, data };
  }

  function success(data) {
    
    return { type: userConstants.TEAMORG_SUCCESS, data };
  }

  function failure(error) {
    
    return { type: userConstants.TEAMORG_FAILURE, error };
  }
}

function get_schedule(id) {
  return (dispatch) => {
    const sections = [];

    dispatch(request({}));

    userService.getWrapper(
      `api/schedule/${id}`,
      (data) => {
        dispatch(success(data.data));

        dispatch(request_sections({}));

        userService.getWrapper(
          `api/section?conference_id=${id}`,
          (data) => {
            dispatch(success_sections(data.data));
          },
          (error) => {
            dispatch(failure_sections(error));
          }
        );
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request_sections(data) {
    return { type: userConstants.SECTIONS_REQUEST, data };
  }

  function success_sections(data) {
    
    return { type: userConstants.SECTIONS_SUCCESS, data };
  }

  function failure_sections(error) {
    
    return { type: userConstants.SECTIONS_FAILURE, error };
  }

  function request(data) {
    return { type: userConstants.SCHEDULE_REQUEST, data };
  }

  function success(data) {
    
    return { type: userConstants.SCHEDULE_SUCCESS, data };
  }

  function failure(error) {
    
    return { type: userConstants.SCHEDULE_FAILURE, error };
  }
}

function superAuth(id) {
  axios.get(`${serverUrl}api/superuser/token/${id}`).then(
    (user) => {
      const data = { ...user.data };

      setStorage("token", data.token);

      axios.defaults.headers.post['Authorization'] = 'Bearer '+user.data;
      axios.defaults.headers.get['Authorization'] = 'Bearer '+user.data;
      axios.defaults.headers.patch['Authorization'] = 'Bearer '+user.data;
      axios.defaults.headers.delete['Authorization'] = 'Bearer '+user.data;

      localStorage.setItem("conf", '{"id":0}');
      localStorage.setItem("user", "{}");
      localStorage.setItem("navic_redirect", "");
      history.push("/");
    },
    (error) => {}
  );
}