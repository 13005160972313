import { userService } from "./user.service";
import { store } from "../_helpers";
import { appActions, userActions, scheduleActions } from "../_actions";
import i18next from "i18next";
import { tmpDescI18N } from "../TimePage/TimeTable";
import { duration } from "@material-ui/core";

export const scheduleService = {
  getSchedule,
  updateSchedule,
  getThemeList,
  getRoomList,
  getSpeakerList,
  addTheme,
  delTheme,
  editTheme,
  addServiceTheme,
  delServiceTheme,
  editServiceTheme,
  editSlotDesc,
  delRoom,
  addRoom,
  editRoom,
  addSpeaker,
  delSpeaker,
  editSpeaker,
  delSection,
  addSection,
  editSection,
  setSlotMode,
  setSchedule,
  setSlots,
  changeSectionName,
  changeSectionOrderType,
  changeSectionModers,
  saveUrl,
};

function getSchedule(id, curDate) {
  const state = store.getState();

  if (curDate) {
    store.dispatch(scheduleActions.set_date(curDate));
  } else {
    curDate = state.curDate;
  }

  userService.getWrapper(
    `api/schedule/${id}`,
    function (data) {
      getLessons(data, id, curDate);
      getServiceThemeList();
    },
    function (error) {
      if (error != "") {
        store.dispatch(userActions.set_error(error));
      }
    }
  );
}

function getLessons(data, id, curDate) {
  let lessons = [...data.data.lessons];
  const allThemesId = [];

  for (let i = 0; i < lessons.length; i++) {
    let oldDate = "";
    if (lessons[i].date) {
      oldDate = new Date(lessons[i].date);

      let dayData = "" + oldDate.getDate();
      if (dayData.length < 2) dayData = "0" + dayData;

      let mData = "" + (1 + oldDate.getMonth());
      if (mData.length < 2) mData = "0" + mData;

      const newDate = oldDate.getFullYear() + "-" + mData + "-" + dayData;
      lessons[i].date = newDate;
    }

    if (lessons[i].date) {
      if (lessons[i].entity) allThemesId.push(lessons[i].entity.id);
      else allThemesId.push(lessons[i].id);
    }
  }

  // self.setState({allLessons:[...lessons],allThemeList:allThemesId});
  store.dispatch(scheduleActions.set_lessons(lessons));
  store.dispatch(scheduleActions.set_themes_id(allThemesId));

  getThemeList(id, allThemesId);
  filterLessons(lessons, id, curDate);
}

function filterLessons(lessonsSrc, id, curDate) {
  const sections = [];
  let slots = [];
  let lessons = [];
  const allThemesId = [];

  if (curDate) {
    for (let i = 0; i < lessonsSrc.length; i++) {
      if (lessonsSrc[i].date && lessonsSrc[i].date.indexOf(curDate) >= 0) {
        lessons.push({ ...lessonsSrc[i] });
      }
    }
  } else {
    lessons = [...lessonsSrc];
  }

  for (let i = 0; i < lessons.length; i++) {
    let tmp_obj = {
      theme: "",
      room: "",
      speakers: [],
      id: lessons[i].id,
      url_online: lessons[i].url_online,
      type: lessons[i].type,
      status: lessons[i].status,
      payment_status: lessons[i].payment_status,
      date: lessons[i].date,
      start_time: lessons[i].start_time,
      duration: lessons[i].duration,
      slot_type: lessons[i].slot_type,
      entity: lessons[i].entity,
      description: lessons[i].description,
    };

    tmp_obj.theme = lessons[i].name;
    tmp_obj.entity = { ...lessons[i].entity };

    if (tmp_obj.entity && tmp_obj.entity.id) {
      tmp_obj.entity.speakers = lessons[i].entity.speakers
        ? [...lessons[i].entity.speakers]
        : [];
      if (tmp_obj.entity.speakers.length == 0) {
        tmp_obj.entity.speakers = [
          { name: "", status: "0", payment_status: "0" },
        ];
      }
    } else {
      tmp_obj.speakers = lessons[i].speakers ? [...lessons[i].speakers] : [];
      if (tmp_obj.speakers.length == 0) {
        tmp_obj.speakers = [{ name: "", status: "0", payment_status: "0" }];
      }
    }

    if (lessons[i].room_number) {
      tmp_obj = {
        ...tmp_obj,
        room: lessons[i].room_number,
        room_id: lessons[i].room_id,
      };
    }

    if (lessons[i].section) {
      const sect_id = lessons[i].section.id;
      let sect_index = -1;

      if (sections.length > 0) {
        for (let j = 0; j < sections.length; j++)
          if (sections[j].id == sect_id) {
            sect_index = j;
            break;
          }
      }

      if (sect_index < 0) {
        sections.push({ ...lessons[i].section, slots: [] });
        sect_index = sections.length - 1;
      }

      if (lessons[i].section) {
        allThemesId.push(lessons[i].id);
      }

      sections[sect_index].slots.push({ ...tmp_obj });
    } else if (lessons[i].date) {
      slots.push({ ...tmp_obj });
    }
  }

  store.dispatch(scheduleActions.set_slots(slots));

  let sections1 = sections.filter((e) => e.slots && e.slots.length > 0);

  console.log("SS", sections1);

  getSectionAPI(id, sections1, lessonsSrc);
}

function getSectionAPI(id, sections_src, allLessons) {
  const sections = [...sections_src];

  // let sectIdArray = sections.map(e=>e.id);

  const sectIdArray = [];
  for (let i = 0; i < allLessons.length; i++) {
    if (
      allLessons[i].section &&
      allLessons[i].section.id &&
      sectIdArray.indexOf(allLessons[i].section.id) < 0
    )
      sectIdArray.push(allLessons[i].section.id);
  }

  console.log("S OLD", sections);

  userService.getWrapper(
    "api/section?conference_id=" + id,
    function (data) {
      if (data.status == 200) {
        const tmpSect = [...data.data];

        console.log("TT", tmpSect);

        for (let i = 0; i < tmpSect.length; i++) {
          let description_i18n = tmpDescI18N;

          const all_sect_data = sections_src.filter(
            (item) => item.id == tmpSect[i].id
          );

          let speakers = [];

          if (all_sect_data.length == 1 && all_sect_data[0].speakers) {
            speakers = all_sect_data[0].speakers;
          }

          if (
            tmpSect[i].description_i18n &&
            tmpSect[i].description_i18n["ru"]
          ) {
            try {
              description_i18n = {
                ru: tmpSect[i].description_i18n["ru"] ?? "",
                en: tmpSect[i].description_i18n["en"] ?? "",
                cn: tmpSect[i].description_i18n["cn"] ?? "",
              };
            } catch (e) {
              console.log(e);
            }
          }

          let description = "";

          try {
            description = tmpSect[i].description ?? "";
          } catch (e) {
            console.log(e);
          }

          if (sectIdArray.indexOf(tmpSect[i].id) < 0) {
            console.log("SECT", description, description_i18n, tmpSect[i]);

            sections.push({
              ...tmpSect[i],
              duration: (tmpSect[i].duration ?? 0) / 60,
              slots: [],
              speakers: speakers,
            });
          } else {
            let index = sections.map((item) => item.id).indexOf(tmpSect[i].id);
            if (index >= 0) {
              let name_i18n = tmpSect[i].name_i18n;
              if (!name_i18n)
                name_i18n = { ru: sections[index].name, en: "", cn: "" };

              sections[index] = {
                ...sections[index],
                name_i18n: name_i18n,
                description,
                description_i18n,
                start_time: tmpSect[i].start_time,
                duration: (tmpSect[i].duration ?? 0) / 60,
                url: tmpSect[i].url,
                room_id: tmpSect[i].room_id,
                speakers: speakers,
              };
            }
          }
        }

        sections.sort((a, b) => {
          if (a.order_id && b.order_id) {
            return b.order_id - a.order_id;
          }
        });

        console.log("RES SECT", sections);

        // self.setState({sections:[...sections],isLoading:false});
        store.dispatch(scheduleActions.set_schedule(sections));
      }
      // self.setState({isLoading:false});
      else store.dispatch(userActions.set_error(data.status));
    },
    (error) => {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function updateSchedule(id, post_data, curDate, is_reload = false) {
  //is_reload = true;

  userService.postWrapper(
    `api/schedule/${id}`,
    post_data,
    function (data) {
      if (data.data && data.data.lessons && is_reload == false) {
        getLessons(data, id, curDate);
      } else {
        getSchedule(id, curDate);
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function getThemeList(id, allThemeList = null) {
  if (!allThemeList) {
    const state = store.getState();

    allThemeList = [...state.schedule.themes_id];
  }

  userService.getWrapper(
    `api/lesson?conference_id=${id}`,
    function (data) {
      if (data.data && data.data.length > 0) {
        //const tmpThemes = data.data.filter((e) => e.name);
        const tmpThemes = data.data;

        for (let i = 0; i < tmpThemes.length; i++) {
          if (allThemeList.indexOf(tmpThemes[i].id) >= 0) {
            tmpThemes[i].selected = true;
          }
          if (!tmpThemes[i].name) {
            tmpThemes[i].name = "";
          }
        }

        store.dispatch(scheduleActions.set_themes(tmpThemes));
      } else {
        store.dispatch(scheduleActions.set_themes([]));
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function getServiceThemeList() {
  userService.getWrapper(
    `api/servicelesson`,
    function (data) {
      if (data.data && data.data.length > 0) {
        let tmpThemes = [...data.data];
        store.dispatch(scheduleActions.set_service_themes(tmpThemes));
      } else {
        store.dispatch(scheduleActions.set_service_themes([]));
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function addTheme(id, post_data, formData = null) {
  userService.postWrapper(
    `api/lesson?conference_id=${id}`,
    post_data,
    function (data) {
      if (formData && data.data) {
        formData.append("documentable_type", "lesson");
        formData.append("documentable_id", data.data);
        uploadFileData(formData, id);
      } else getThemeList(id);
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function editTheme(id, lesson_id, p_data, curDate, formData = null) {
  userService.patchWrapperBody(
    `api/lesson/${lesson_id}?conference_id=${id}`,
    { ...p_data },
    function (data) {
      if (formData) {
        formData.append("documentable_type", "lesson");
        formData.append("documentable_id", lesson_id);
        uploadFileData(formData, id);
      } else {
        getThemeList(id);
        getSchedule(id, curDate);
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function delTheme(c_id, id, post_data, isSlot = false) {
  const state = store.getState();

  //if (isSlot)
  //    post_data.entity_type = "slot";

  const { curDate } = state.schedule;

  userService.delWrapper(
    `api/lesson/${id}`,
    function (data) {
      if (data.status == 200) {
        if (post_data) {
          updateSchedule(c_id, post_data, curDate);
        } else {
          getThemeList(c_id);
        }
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error.statusText));
    }
  );
}

function addServiceTheme(name, formData = null) {
  userService.postWrapper(
    `api/servicelesson?name=${name}`,
    formData,
    function () {
      getServiceThemeList();
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function editServiceTheme(c_id, cDate, id, name, formData = null) {
  userService.postWrapper(
    `api/servicelesson/update/${id}?name=${name}`,
    formData,
    function (data) {
      getServiceThemeList();
      //getSchedule(c_id,cDate);
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function editSlotDesc(c_id, cDate, id, desc) {
  userService.patchWrapper(
    `api/slot/${id}?description=${desc}`,
    function (data) {
      getSchedule(c_id, cDate);
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function delServiceTheme(id) {
  const state = store.getState();

  userService.delWrapper(
    `api/servicelesson/${id}`,
    function (data) {
      if (data.status == 200) {
        getServiceThemeList();
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error.statusText));
    }
  );
}

function getRoomList(id) {
  let url = "api/room";
  if (id) url = `api/room?conference_id=${id}`;

  userService.getWrapper(
    url,
    function (data) {
      if (data.data && data.data.length > 0) {
        store.dispatch(scheduleActions.set_rooms([...data.data]));
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function getSpeakerList(id = 0) {
  let self = this;

  let url = "api/speaker";
  if (id > 0) url = url + "?conference_id=" + id;

  userService.getWrapper(
    url,
    function (data) {
      if (data.data && data.data.length > 0)
        store.dispatch(scheduleActions.set_speakers([...data.data]));
      else {
        store.dispatch(scheduleActions.set_speakers([]));
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function addSpeaker(id, json_data) {
  userService.postWrapper(
    "api/speaker",
    json_data,
    function (data) {
      getSpeakerList(id);
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function editSpeaker(c_id, id, data, curDate) {
  userService.patchWrapperBody(
    `api/speaker/${id}`,
    data,
    function (data) {
      if (data.status == 200) {
        getSpeakerList(c_id);
        getSchedule(c_id, curDate);
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function delSpeaker(c_id, id) {
  userService.delWrapper(
    `api/speaker/${id}`,
    function (data) {
      getSpeakerList(c_id);
    },
    function (error) {
      store.dispatch(
        userActions.set_error(i18next.t("speaker_added_to_section"))
      );
    }
  );
}

function addRoom(id, json_data) {
  userService.postWrapper(
    "api/room",
    json_data,
    function (data) {
      if (data.status == 200) {
        getRoomList(id);
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function editRoom(id, json_data, c_id, curDate) {
  userService.patchWrapperBody(
    `api/room/${id}`,
    json_data,
    function (data) {
      if (data.status == 200) {
        getRoomList(c_id);
        getSchedule(c_id, curDate);
      } else {
        store.dispatch(userActions.set_error(data.status));
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function delRoom(c_id, id) {
  const state = store.getState();

  let { rooms, sections } = state.schedule;

  let roomName = "";

  for (let i = 0; i < rooms.length; i++) {
    if (rooms[i].id == id) {
      roomName = rooms[i].number;
      break;
    }
  }

  let isCanDelete = true;

  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].slots.length; j++)
      if (sections[i].slots[j].room == roomName && roomName != "") {
        isCanDelete = false;
        break;
      }
  }

  if (isCanDelete == true) {
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i].id == id) {
        rooms = rooms.filter((e) => e.id != id);
        break;
      }
    }

    userService.delWrapper(
      `api/room/${id}`,
      function (data) {
        getRoomList(c_id);
      },
      function (error) {
        store.dispatch(userActions.set_error(i18next.t("room_used")));
      }
    );
  } else {
    store.dispatch(userActions.set_error(i18next.t("room_used")));
  }
}

function delSection(c_id, ind, curDate) {
  const state = store.getState();

  const { sections } = state.schedule;

  if (sections[ind].id) {
    userService.delWrapper(
      `api/section/${sections[ind].id}`,
      function (data) {
        getSchedule(c_id, curDate);
      },
      function (error) {
        store.dispatch(userActions.set_error(error));
      }
    );
  } else {
    getSchedule(c_id, curDate);
  }
}

function addSection(c_id, val) {}

function editSection(c_id, id, val) {}

function setSchedule(sections) {
  store.dispatch(scheduleActions.set_schedule(sections));
}

function setSlots(slots) {
  store.dispatch(scheduleActions.set_slots(slots));
}

function setSlotMode(c_id, sectIndex, slotIndex, format) {
  const state = store.getState();
  const { sections, slots } = state.schedule;
  let id = null;
  let tp = null;

  if (sectIndex >= 0) {
    id = sections[sectIndex].slots[slotIndex].id;
    tp = sections[sectIndex].slots[slotIndex].type;
  } else {
    id = slots[slotIndex].id;
    tp = slots[slotIndex].type;
  }

  if (format == "hybrid") {
    let newTp = "offline";
    if (!tp || tp == "offline") {
      newTp = "online";
    } else if (tp == "online") {
      newTp = "hybrid";
    }

    userService.patchWrapper(
      `api/lesson/${id}?type=${newTp}&conference_id=${c_id}`,
      function (data) {
        if (data.status == 200) {
          if (sectIndex >= 0) {
            sections[sectIndex].slots[slotIndex].type = newTp;
            store.dispatch(scheduleActions.set_schedule(sections));
          } else {
            slots[slotIndex].type = newTp;
            store.dispatch(scheduleActions.set_slots(slots));
          }
        }
      },
      function (error) {
        store.dispatch(userActions.set_error(error));
      }
    );
  }
}

function changeSectionName(id, e, index, curDate) {
  const state = store.getState();
  const { sections } = state.schedule;

  console.log("change1", e);

  let sectNameExists = false;

  let newDesc18N = e.description_i18n ?? tmpDescI18N;

  if (sectNameExists == false) {
    if (index >= -10) {
      let sectId = null;
      if (index >= 0) sectId = sections[index].id;

      let start_time = e.start_time;

      if (start_time) {
        let tmp_arr_time = start_time.split(":");
        if (tmp_arr_time.length > 2)
          start_time = tmp_arr_time[0] + ":" + tmp_arr_time[1];
      }

      let post_obj = {
        name: e.name,
        name_i18n: e.name_i18n,
        description: e.destcription,
        description_i18n: newDesc18N,
        room_id: e.room > 0 ? +e.room : null,
        url: e.url,
      };

      if (start_time) post_obj.start_time = start_time;

      if (duration) post_obj.duration = e.duration * 60;

      if (sectId) {
        userService.patchWrapperBody(
          "api/section/" + sectId + "?conference_id=" + id,
          post_obj,
          function (data) {
            if (data.status == 200) {
              getSchedule(id, curDate);
            }
          },
          function (error) {
            store.dispatch(userActions.set_error(error));
          }
        );
      } else {
        let max_order = 0;
        for (let i = 0; i < sections.length; i++) {
          if (
            sections[i].order_id &&
            sections[i].order_id > 0 &&
            sections[i].order_id > max_order
          )
            max_order = sections[i].order_id;
        }

        userService.postWrapper(
          "api/section",
          {
            name: e.name,
            name_i18n: e.name_i18n,
            description: e.description,
            description_i18n: newDesc18N,
            room_id: e.room > 0 ? +e.room : null,
            start_time: e.start_time,
            duration: e.duration * 60,
            url: e.url,
            order_id: max_order + 1,
            conference_id: id,
          },
          function (data) {
            if (data.status == 200) {
              getSchedule(id, curDate);
            }
          },
          function (error) {
            store.dispatch(userActions.set_error(error));
          }
        );
      }
    } else {
      store.dispatch(userActions.set_error(i18next.t("section_error_refresh")));
    }
  }
}

function changeSectionModers(conf_id, id, val, curDate) {
  userService.patchWrapperBody(
    "api/section/" + id + "?conference_id=" + conf_id,
    { speakers: [...val] },
    function (data) {
      if (data.status == 200) {
        getSchedule(conf_id, curDate);
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function changeSectionOrderType(conf_id, id, val, curDate) {
  userService.patchWrapperBody(
    "api/section/" + id + "?conference_id=" + conf_id,
    { sort_mode: val ? "order" : "time" },
    function (data) {
      if (data.status == 200) {
        getSchedule(conf_id, curDate);
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function saveUrl(conf_id, id, url, curDate) {
  userService.patchWrapperBody(
    `api/lesson/${id}`,
    { url_online: url ? url + "" : "", conference_id: conf_id },
    function (data) {
      if (data.status == 200) {
        store.dispatch(
          userActions.set_error(i18next.t("url_saved"), "success")
        );
        getSchedule(conf_id, curDate);
      }
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function uploadFileData(data_upload, c_id) {
  userService.uploadFile(
    "api/document",
    data_upload,
    function (data) {
      getThemeList(c_id);
    },
    function (error) {
      store.dispatch(userActions.set_error(error));
      getThemeList(c_id);
    }
  );
}
