import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import HelpIcon from "@material-ui/icons/Help";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TabEditor(props) {
  const {
    id,
    type,
    value,
    error,
    name,
    label,
    placeholder,
    className,
    autoFocus,
    inputProps,
    onChange,
    onLeave,
    size,
    disabled,
    langList,
    isMultilang,
  } = props;

  const [tabIndex, setTabIndex] = React.useState(0);

  function handleChange(e) {
    onChange(e, langList[tabIndex]);
  }

  function changeTab(val) {
    setTabIndex(val);
  }

  function SimpleDialog(props) {
    const { onClose, hint, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{hint}</DialogTitle>
      </Dialog>
    );
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      {isMultilang && (
        <TabContext value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(e, val) => changeTab(val)}
              aria-label="lab API tabs example"
            >
              {langList.map((item, index) => (
                <Tab label={item} value={index} />
              ))}
            </TabList>
          </Box>
        </TabContext>
      )}
      <ReactQuill
        theme="snow"
        value={isMultilang ? value[langList[tabIndex]] : value}
        onChange={handleChange}
      />
      ;
    </Box>
  );
}

export default TabEditor;
